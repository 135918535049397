.home{
    width: 100%;
    height: 100vh;
    background-color: aliceblue;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iframe{
    flex: 1;
    height: 80vh;
    display: flex;

    flex-direction:column;
    margin-left: 5vw;
    margin-right: 5vw;
}

.iframe >p {
    align-self: flex-start;
    font-size: 2.5vh;
    font-weight: bold;
}

iframe{
    flex: 1;
    width: 100%;
    background-color: white;
    border: 1px solid rgba(1, 1, 1, 0.3);
}

.extrapannel{
    padding-left: 1.5vh;
    padding-right: 1.5vh;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.extrapannel >p {
    font-weight: bold;
}