.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    /* background-color: red; */
}

table, th, td {
    border-collapse: collapse;
  }

.tr{
    border-style: solid;
    border: 0px;
    border-color: rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    /* background-color: aliceblue; */
}

.tr > th{
    padding-top: 2.2vh;
    padding-bottom: 2.5vh;
    text-align: left;
    padding-left: 1.5vh;
}

.tr > td{
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    text-align: left;
    padding-left: 1.5vh;
}

.vp ,.vpost {
    color: blue;
    cursor: pointer;
}